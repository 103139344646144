import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import Select, { ISelectProps } from "components/UI/atoms/Select";

import { measuresUtils } from "../lib/measuresUtils";
import { getMeasures } from "../model/thunks";
import { useTypedSelector } from "app/store/typedUseSelector";

import { MeasureTypes } from "../model/types";

interface IProps extends Omit<ISelectProps, "options"> {
  type?: MeasureTypes | MeasureTypes[];
}

export const MeasureSelectV2: FC<IProps> = ({ type, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMeasures(type));
  }, [type]);

  const key = measuresUtils.getKeyFromType(type);
  const isLoading = useTypedSelector((state) => state.measuresReducer.loadings)[key];
  const options = useTypedSelector((state) => state.measuresReducer.measures)[key];

  return <Select isLoading={isLoading} placeholder={"-"} options={options ?? []} {...props} />;
};
