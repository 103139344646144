import { IOption } from "components/UI/atoms/Select";

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface MeasuresState {
  loadings: Record<string, boolean>;
  measures: Record<string, IOption[]>;
}

export const initialState: MeasuresState = {
  loadings: {},
  measures: {},
};

const measuresSlice = createSlice({
  name: "measures",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<{ status: boolean; key: string }>) => {
      const { status, key } = action.payload;
      state.loadings[key] = status;
    },
    setMeasures: (state, action: PayloadAction<{ measures: string[]; key: string }>) => {
      const { measures, key } = action.payload;
      state.measures[key] = measures.map((el) => ({ id: el, name: el }));
    },
  },
});

export const measuresActions = measuresSlice.actions;

export const measuresReducer = measuresSlice.reducer;
