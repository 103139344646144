import cn from "classnames";
import { memo } from "react";

import InputBase, { VALUE_TYPES } from "../../../../../../../../../shared/ui/inputs/InputBase";
import { MeasureSelectV2 } from "features/measures";
import { Controller, useFormContext } from "react-hook-form";

import { EXPENDITURE_TYPES_NAMES } from "../../../../../../../../../constants/constant";
import { IExpenditureFormState } from "../types";

import SelectArrow from "../../../../../../../../../shared/assets/icons/SelectArrowIcon.svg";
import { ReactComponent as CloseIcon } from "shared/assets/icons/IconClose.svg";

import styles from "./ResourceRow.module.scss";

interface IProps {
  idx: number;
  hasRemoveButtonPadding?: boolean;
  isRemovable?: boolean;
  onRemove?: () => void;
}

const transformOptions = { withFloat: true, dropZeros: true };

export const ResourceRow = memo<IProps>(({ idx, onRemove, isRemovable = false, hasRemoveButtonPadding = false }) => {
  const { control, getValues, setValue } = useFormContext<IExpenditureFormState>();

  return (
    <div className={cn(styles.container, (isRemovable || hasRemoveButtonPadding) && styles.removable)}>
      <div className={cn(styles.field, styles.name)}>
        <div className={styles.dashedLine} />

        <Controller
          control={control}
          name={`resources.${idx}.type`}
          render={({ field }) => <div className={styles.type}>{EXPENDITURE_TYPES_NAMES[field.value]}:</div>}
        />

        <Controller
          control={control}
          name={`resources.${idx}.name`}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="Введите наименование"
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.mediumWidth)}>
        <Controller
          control={control}
          name={`resources.${idx}.measure`}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <MeasureSelectV2
              value={field.value ?? ""}
              onChange={(v) => field.onChange(v)}
              containerClassName={styles.selectContainer}
              selectClassName={cn(styles.select, fieldState.error && styles.error)}
              placeholder={<span className={styles.placeholder}>Выберите</span>}
              icon={SelectArrow}
              type="material"
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.mediumWidth)}>
        <Controller
          control={control}
          name={`resources.${idx}.count`}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const price = getValues(`resources.${idx}.price`);
                setValue(`resources.${idx}.amount`, (+e.target.value * +price).toString());
              }}
              valueType={VALUE_TYPES.NUMBER}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="0"
              floatConstraint={8}
              meta={{ error: fieldState.error }}
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.largerWidth)}>
        <Controller
          control={control}
          name={`resources.${idx}.price`}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const count = getValues(`resources.${idx}.count`);
                setValue(`resources.${idx}.amount`, (+e.target.value * +count).toString());
              }}
              valueType={VALUE_TYPES.PRICE}
              className={styles.inputContainer}
              classNameInput={cn(styles.input, fieldState.error && styles.error)}
              placeholder="00.00"
              icon={<span className={styles.inputIcon}>₽</span>}
              numberTransformOptions={transformOptions}
              floatConstraint={2}
            />
          )}
        />
      </div>

      <div className={cn(styles.field, styles.largerWidth)}>
        <Controller
          control={control}
          name={`resources.${idx}.amount`}
          render={({ field }) => (
            <InputBase
              value={field.value?.toString() ?? ""}
              onChange={(e) => {
                field.onChange(e.target.value);

                const count = getValues(`resources.${idx}.count`);

                setValue(`resources.${idx}.price`, (+e.target.value / +count).toString());
              }}
              valueType={VALUE_TYPES.PRICE}
              className={styles.inputContainer}
              classNameInput={cn(styles.input)}
              placeholder="00.00"
              icon={<span className={styles.inputIcon}>₽</span>}
              numberTransformOptions={transformOptions}
              floatConstraint={2}
            />
          )}
        />
      </div>

      {isRemovable && (
        <div className={styles.field}>
          <button className={styles.removeButton} onClick={() => onRemove?.()}>
            <CloseIcon className={styles.removeIcon} />
          </button>
        </div>
      )}
    </div>
  );
});

ResourceRow.displayName = "ResourceRow";
