import axios from "axios";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Field, Form } from "react-final-form";

import {
  addAdditionalMaterial,
  addMaterial,
} from "../../../../../../redux/modules/common/building/sections/sectionsApi";

import Select from "../../../../../../components/UI/atoms/Select";

import ButtonBase from "../../../../../../shared/ui/controls/ButtonBase";
import SelectedSectionField from "../../SelectedSectionField/SelectedSectionField";
import { MeasureSelectV2 } from "features/measures";
import Input, { VALUE_TYPES } from "shared/ui/inputs/InputBase";
import InputBase from "shared/ui/inputs/InputBase";

import { measures } from "constants/constant";

import useFormFocus from "../../../../../../utils/hooks/useFormFocus";

import { composeFieldValidators, maxLength, required } from "utils/formHelpers/validations";
import { errorCatcher } from "utils/helpers/errorCatcher";

import styles from "./NewProductForm.module.scss";

const TAG_OPTIONS = [
  { id: "expendable_material", name: "Расходный материал" },
  { id: "instrument", name: "Инструмент" },
  { id: "provision", name: "Обеспечение" },
  { id: "shaped_element", name: "Фасонный элемент" },
];

const mimsOptions = [
  { id: "bulldozer", name: "Бульдозер" },
  { id: "tractor", name: "Трактор" },
  { id: "excavator", name: "Экскаватор" },
  { id: "dump_truck", name: "Самосвал" },
  { id: "forklift", name: "Погрузчик" },
  { id: "autocrane", name: "Автокран" },
  { id: "tower_crane", name: "Башенный кран" },
  { id: "autotower", name: "Автовышка" },
  { id: "mechanism", name: "Механизм" },
];

const NewProductForm = ({ objectId, sections, handleAddProducts, permissions }) => {
  const formRef = useRef(null);
  const inputRootRef = useFormFocus(formRef?.current?.form?.submit);

  const handleSubmit = useCallback(
    async ({ section, ...values }) => {
      if (values?.from_estimate) {
        //TODO перенести
        axios
          .post(`/building/${objectId}/materials/mim/`, {
            ...values,
            section_id: section?.id,
            section_name: section?.name,
            ret_own_mat: false,
          })
          .then((response) => handleAddProducts([{ ...response.data, required_count: response.data.count }]))
          .catch(errorCatcher);
        return;
      }
      if (values?.from_estimate) {
        addAdditionalMaterial(objectId, {
          ...values,
          section_id: section?.id,
          section_name: section?.name,
          ret_own_mat: false,
        }).then((response) => handleAddProducts([{ ...response.data, required_count: response.data.count }]));
      } else {
        addMaterial(objectId, {
          ...values,
          section_id: section?.id,
          section_name: section?.name,
          ret_own_mat: false,
        }).then((response) => handleAddProducts([{ ...response.data, required_count: response.data.count }]));
      }
    },
    [handleAddProducts, objectId]
  );

  const fromEstimateOptions = useMemo(() => {
    const result = [];

    if (permissions.viewAddOutOfEstimateProducts) result.push({ id: false, name: "Вне сметы" });
    if (permissions.viewAddAdditionalProducts) result.push({ id: true, name: "Доп" });

    result.push({ id: "mim", name: "МиМ" });

    return result;
  }, [permissions]);

  const [measureType, setMeasureType] = useState();

  return (
    <div className={styles.container}>
      <Form
        ref={formRef}
        onSubmit={(values) => handleSubmit(values)}
        mutators={{
          setFromEstimate: ([v], state, tools) => {
            tools.changeValue(state, "tag", () => undefined);
            tools.resetFieldState("tag");
          },
        }}
        validate={(values) => {
          const errors = {};

          if (typeof values.from_estimate !== "boolean" && values.from_estimate !== "mim") {
            errors.from_estimate = required()();
          } else {
            if (!values.from_estimate && values.tag === undefined) {
              errors.tag = required()();
            }
            if (values.from_estimate && values.section === undefined) {
              errors.section = required()();
            }
          }

          return errors;
        }}
        render={({ handleSubmit, reset, values, form }) => (
          <form onSubmit={(e) => handleSubmit(e).then(reset)}>
            <div className={styles.content} ref={inputRootRef}>
              <div className={styles.formRow}>
                <Field
                  name="section"
                  // validate={!values.from_estimate && composeFieldValidators(required())}
                  component={SelectedSectionField}
                  sections={sections}
                  objectId={objectId}
                  disabled={!values.from_estimate}
                />
                <div>
                  <Field
                    name="from_estimate"
                    options={fromEstimateOptions}
                    value={values.from_estimate}
                    className={styles.select}
                    render={({ input, meta }) => (
                      <Select
                        {...input}
                        name="from_estimate"
                        className={styles.select}
                        value={values.from_estimate}
                        options={fromEstimateOptions}
                        meta={meta}
                        onChange={(e) => {
                          input.onChange(e);
                          form.mutators.setFromEstimate(e);
                          if (e === "mim") {
                            setMeasureType("transport");
                          } else {
                            setMeasureType("material");
                          }
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name="tag"
                    // validate={values.from_estimate && composeFieldValidators(required())}
                    selfControlled
                    disabled={values.from_estimate}
                    render={({ input, meta }) => (
                      <Select
                        options={measureType === "material" ? TAG_OPTIONS : mimsOptions}
                        className={styles.select}
                        input={input}
                        meta={{
                          ...meta,
                          touched: meta.touched || values["from_estimate"] === false,
                        }}
                      />
                    )}
                  />
                </div>
                <ButtonBase onClick={handleSubmit}>Добавить</ButtonBase>
              </div>
              <div className={styles.formRow2}>
                <div className={styles.field}>
                  <Field
                    label="Наименование *"
                    name="name"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Марка"
                    name="brand"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Код"
                    name="number"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    name="count"
                    render={(props) => {
                      return (
                        <InputBase
                          classNameInput={styles.input}
                          name="count"
                          meta={props.meta}
                          value={props.input.value.replaceAll(",", ".")}
                          label="Количество *"
                          onChange={(e) => props.input.onChange(e)}
                          valueType={VALUE_TYPES.NUMBER}
                        />
                      );
                    }}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div>
                  <Field
                    name="measure"
                    selfControlled={true}
                    validate={composeFieldValidators(required())}
                    render={({ meta, input }) => (
                      <MeasureSelectV2
                        className={styles.select2}
                        label="Ед.измерения *"
                        meta={meta}
                        input={input}
                        type={measureType}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default React.memo(NewProductForm);
