import { Dispatch } from "redux";

import { measuresUtils } from "../lib/measuresUtils";
import { measuresApi } from "./api";
import { measuresActions } from "./slice";
import { RootState } from "app/store/rootReducer";

import { MeasureTypes } from "./types";

import { errorCatcher } from "utils/helpers/errorCatcher";

export const getMeasures =
  (type?: MeasureTypes | MeasureTypes[]) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = measuresUtils.getKeyFromType(type);
    if (!getState().measuresReducer.measures[key]) {
      dispatch(measuresActions.setIsLoading({ status: true, key }));
    }
    measuresApi
      .get(type)
      .then(({ data }) => {
        dispatch(measuresActions.setMeasures({ key, measures: data }));
      })
      .catch(errorCatcher)
      .finally(() => {
        dispatch(measuresActions.setIsLoading({ status: false, key }));
      });
  };
