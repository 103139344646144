import cn from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Field } from "react-final-form";

import AutoCompleteOutOfEstimate from "pages/Handler/ui/ProHandler/widgets/AutoCompleteOutOfEstimate/AutoCompleteOutOfEstimate";
import { IHelpExpanditure } from "pages/Handler/ui/ProHandler/widgets/AutoCompleteOutOfEstimate/model/types";

import Actions from "../../../../shared/ui/controls/Actions/Actions";
import InputBase, { VALUE_TYPES } from "../../../../shared/ui/inputs/InputBase";
import Select from "../../atoms/Select";
import PopoverOverlay from "../PopoverOverlay/PopoverOverlay";
import { MeasureSelectV2 } from "features/measures";
import { FormApi } from "final-form";

import { EXPENDITURE_TYPES } from "../../../../constants/constant";
import { ExpenditureType } from "types/enums/ExpenditureTypeEnum";

import { composeFieldValidators, maxLength, mustBeNumber, required } from "../../../../utils/formHelpers/validations";

import styles from "./ExpenditureFormRow.module.scss";

interface IProps {
  submit: (vals: any) => void;
  requiredFields?: string[];
  onCancel: () => void;
  isWork?: boolean;
  onAddNew?: () => void;
  handleSubmitForm?: () => void;
  id?: number;
  showLabels?: boolean;
  isClickCancelDirectly?: boolean;
  onSelectExpenditure: (item: IHelpExpanditure) => void;
  expType: ExpenditureType;
  form: FormApi<any, Partial<any>>;
  isWithNumberVariant?: boolean;
}

const ExpenditureFormRow: React.FC<IProps> = (props) => {
  const {
    onCancel,
    isWork = true,
    onAddNew,
    handleSubmitForm,
    id,
    showLabels = true,
    isClickCancelDirectly,
    onSelectExpenditure,
    expType,
    form,
    isWithNumberVariant = false,
  } = props;

  const [expenditureType, setExpenditureType] = useState(isWork ? EXPENDITURE_TYPES.WORK : EXPENDITURE_TYPES.MATERIAL);

  const getFieldName = (name: string) => {
    return isWork ? name : `id:${id}.${name}`;
  };

  const expenditureTypesOptions = useMemo(() => {
    if (isWork) {
      return [{ name: "Работа", id: "work" }];
    } else {
      return [
        { name: "Материал", id: "material" },
        { name: "Транспорт", id: "transport" },
        { name: "Оборудование", id: "equipment" },
      ];
    }
  }, [isWork]);

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.grid, { [styles.withoutNumberVariant]: !isWithNumberVariant })}>
        <div className={styles.numberField}>
          <Field
            name={getFieldName("number")}
            component={InputBase as any}
            label={showLabels ? `Номер:` : undefined}
            validate={composeFieldValidators(required(), maxLength(15))}
          />
        </div>
        <div className={styles.fieldRow5}>
          <Field
            name={getFieldName("expenditure_type")}
            validate={composeFieldValidators(required(), maxLength(155))}
            render={({ input, meta }) => (
              <Select
                input={input}
                options={expenditureTypesOptions}
                label={showLabels ? `Тип позиции:` : undefined}
                disabled={isWork}
                meta={meta}
              />
            )}
          />
        </div>
        <div className={styles.name}>
          <AutoCompleteOutOfEstimate
            label={showLabels ? `Наименование позиции:` : undefined}
            name={"name"}
            formName={getFieldName("name")}
            onSelect={onSelectExpenditure}
            expType={isWork ? "work" : expType}
            formaValue={form.getState().values[getFieldName("name")]}
          />
          {isWork && (
            <div className={styles.add} onClick={onAddNew}>
              <PopoverOverlay
                openType="hover"
                placement="top"
                popoverBorderColor="primary"
                content={<div className={styles.popover}>Добавить связанный ресурс</div>}
              >
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.5 2.41406H11.5V11.4141H2.5V13.4141H11.5V22.4141H13.5V13.4141H22.5V11.4141H13.5V2.41406Z"
                    fill="#4FB1EB"
                  />
                </svg>
              </PopoverOverlay>
            </div>
          )}
        </div>
        <div className={styles.field2}>
          <Field
            name={getFieldName("measure")}
            validate={composeFieldValidators(required(), maxLength(155))}
            render={({ input, meta }) => (
              <MeasureSelectV2
                input={input}
                label={showLabels ? `Ед. Изм. :` : undefined}
                meta={meta}
                type="material"
              />
            )}
          />
        </div>
        <div className={styles.field3}>
          <Field
            name={getFieldName("count")}
            label={showLabels ? `Кол-во:` : undefined}
            type="number"
            render={(props) => <InputBase {...props} valueType={VALUE_TYPES.NUMBER} />}
            validate={composeFieldValidators(mustBeNumber, required(), maxLength(15))}
          />
        </div>
        <div className={styles.field3}>
          <Field
            name={getFieldName("price")}
            label={showLabels ? `Цена за единицу:` : undefined}
            component={InputBase}
            icon={<div className={styles.rouble}>₽</div>}
          />
        </div>

        <Actions
          canSave={isWork}
          canRemove
          onSave={handleSubmitForm}
          onRemove={onCancel}
          className={isWork ? styles.actions : styles.oneAction}
          onRemoveDirectly={isWork && !isClickCancelDirectly ? undefined : onCancel}
          deleteLabel={isWork ? "Отменить" : "Удалить"}
        />
      </div>
    </div>
  );
};

export default React.memo(ExpenditureFormRow);
