import axios from "axios";

import { MeasureTypes } from "./types";

export const measuresApi = {
  get: (rawType?: MeasureTypes | MeasureTypes[]) => {
    const type = Array.isArray(rawType) ? rawType.join(",") : rawType;
    return axios.get<string[]>(`/help/measures/`, { params: { type } });
  },
};
