import update from "immutability-helper";
import { memoize } from "lodash";
import React, { useCallback } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { compose } from "redux";

import InputBase, { VALUE_TYPES } from "../../../../../../shared/ui/inputs/InputBase/InputBaseOrder";
import { MeasureSelectV2 } from "features/measures";

import { getValueFromEventTarget } from "../../../../../../utils/helpers/getValueFromEventTarget";
import { stringifyArgs } from "../../../../../../utils/helpers/stringifyArgs";

import styles from "../../index.module.scss";

const OfferComponentColumns = ({ canEdit, offer, offerComponent, setOffer, requestMeasure }) => {
  const onChangeComponentField = useCallback(
    (changedComponent, name, value) => {
      const changedComponentIndex = offer.components.indexOf(changedComponent);

      setOffer(
        update(offer, {
          components: { [changedComponentIndex]: { $set: { ...changedComponent, [name]: value } } },
        })
      );
    },
    [offer, setOffer]
  );

  const onChangeComponentFieldByName = useCallback(
    (component, name) => memoize((value) => onChangeComponentField(component, name, value), stringifyArgs),
    [onChangeComponentField]
  );

  return (
    <>
      <div className={styles.nameColumn}>
        <span className={styles.componentCircle} />
        {canEdit ? (
          <TextareaAutosize
            className={styles.input}
            value={offerComponent.name}
            placeholder="Наименование предложения"
            onChange={compose(onChangeComponentFieldByName(offerComponent, "name"), getValueFromEventTarget)}
          />
        ) : (
          offerComponent.name
        )}
      </div>
      <div className={styles.dateColumn}>
        {canEdit && offer.measure === "компл" ? (
          <MeasureSelectV2
            className={styles.measureSelect}
            value={offerComponent.measure}
            onChange={onChangeComponentFieldByName(offerComponent, "measure")}
            type="material"
          />
        ) : (
          offerComponent.measure
        )}
      </div>
      <div className={styles.countColumn}>
        {canEdit ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.NUMBER}
            variant="secondary"
            value={offerComponent.count}
            onChange={compose(onChangeComponentFieldByName(offerComponent, "count"), getValueFromEventTarget)}
            placeholder="Кол-во"
          />
        ) : (
          offerComponent.count
        )}
      </div>
      <div className={styles.countColumn}>
        {offerComponent.measure !== requestMeasure &&
          (canEdit ? (
            <InputBase
              className={styles.input}
              valueType={VALUE_TYPES.NUMBER}
              variant="secondary"
              value={offerComponent.count_request_measure || ""}
              onChange={compose(
                onChangeComponentFieldByName(offerComponent, "count_request_measure"),
                getValueFromEventTarget
              )}
              placeholder="Кол-во в ед.изм"
            />
          ) : (
            offerComponent.count_request_measure || ""
          ))}
      </div>
    </>
  );
};

export default OfferComponentColumns;
